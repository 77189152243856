import {ChangeVideo} from './changeVideo';

let changeVideo;

const initChangeVideo = () => {
  const props = {
    videoMedia: '[data-video-media]',
    desktop: 'full',
    mobile: 'full-mob',
  };

  changeVideo = new ChangeVideo(props);
};

export {changeVideo, initChangeVideo};
