import {Burger} from './burger';

let burger;

const initBurger = () => {
  const props = {
    menuOpen: '[data-burger-toggle]',
    menuBody: '[data-burger-body]',
    menuClose: '[data-burger-close]',
  };

  burger = new Burger(props);
};

export {burger, initBurger};
