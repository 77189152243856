export class Burger {
  constructor(props) {
    this.props = props;

    this.open = document.querySelector(props.menuOpen);
    this.body = document.querySelector(props.menuBody);
    this.close = this.body.querySelector(props.menuClose);

    if (this.open !== null) {
      this.init();
    }
  }

  openMenu() {
    this.open.addEventListener('click', () => {
      this.body.classList.add('active');
      this.open.classList.add('active');
      this.close.classList.add('active');
    });
  }

  closeMenu() {
    this.close.addEventListener('click', () => {
      this.body.classList.remove('active');
      this.open.classList.remove('active');
      this.close.classList.remove('active');
    });
  }

  init() {
    this.openMenu();
    this.closeMenu();
  }
}
