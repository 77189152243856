export class ChangeVideo {
  constructor(props) {
    this.props = props;

    this.media = document.querySelector(this.props.videoMedia);
    this.desktop = this.props.desktop;
    this.mobile = this.props.mobile;

    if (this.media) {
      this.init();
    }
  }

  createVideo(src) {
    const code = `
      <video playsinline="" poster="img/video/${src}.jpg" autoplay="" muted="muted" loop="" preload="auto">
        <source src="video/${src}.mp4" type="video/mp4">
      </video>
    `;

    this.media.innerHTML = '';
    this.media.insertAdjacentHTML('afterbegin', code);
  }

  toggleVideo() {
    const windowWith = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (windowWith < 500) {
      this.createVideo(this.mobile);
    } else {
      this.createVideo(this.desktop);
    }
  }

  resizeWindow() {
    window.addEventListener('resize', () => this.toggleVideo());
  }

  init() {
    this.toggleVideo();
    this.resizeWindow();
  }
}
