export class Scroll {
  constructor(props) {
    this.props = props;

    this.header = document.querySelector(this.props.header);
    this.scrollsEl = document.querySelectorAll(props.scrollsEl);
    this.scrollBlock = document.querySelectorAll(props.scrolBlock);

    this.window = window;

    this.init();
  }

  addClass() {
    this.header.classList.add('active');
  }

  removeClass() {
    this.header.classList.remove('active');
  }

  scroll() {
    this.scrollsEl.forEach((el) => {
      el.addEventListener('click', () => {
        this.scrollTo(el.dataset.scrollEl);
      });
    });
  }

  scrollTo(scroll) {
    this.scrollBlock.forEach((block) => {
      if (scroll === block.dataset.scrollBlock) {
        this.window.scrollBy({
          top: block.getBoundingClientRect().top - this.header.offsetHeight - 10,
        });
        this.scrollPage();
      } else {
        return;
      }
    });

  }

  scrollPage() {
    this.window.addEventListener('scroll', () => {
      if (this.window.scrollY > 10) {
        this.addClass();
      } else {
        this.removeClass();
      }
    });
  }

  init() {
    this.scroll();
    this.scrollPage();
  }
}
