import {Scroll} from './scroll';

let scroll;

const props = {
  header: '[data-scroll]',
  scrollsEl: '[data-scroll-el]',
  scrolBlock: '[data-scroll-block]',
};

const initScroll = () => {
  const headerScroll = document.querySelectorAll(props.header);
  const elsScroll = document.querySelectorAll(props.scrollsEl);

  if (headerScroll.length || elsScroll.length) {
    scroll = new Scroll(props);
  }
};

export {scroll, initScroll};
